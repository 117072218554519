import React, { useState, useEffect } from "react";
import { gql, useApolloClient } from "@apollo/client";
import "./App.css";
import Table from "./components/Table";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import StarRating from "./StarRating";
import { IoMdCall, IoMdPrint } from "react-icons/io";

const Logo = require("./logo12345.png");

const GET_ORDER_DETAILS_BY_ID = gql`
  query GetOrderDetailsByOrderId($orderId: String!, $billVisited: Boolean) {
    GetOrderDetailsByOrderId(orderId: $orderId, billVisited: $billVisited) {
      storeId
      orderId
      storeName
      meta {
        cA
      }
      storePhone
      customerName
      customerPhone
      orderTotalDiscount
      orderTotalAmount
      cartTotalAmount
      products {
        productId
        productName
        orderPrice
        orderQuantity
        total
        listingPrice
        productDes
      }
      logistics {
        name
        lspLogo
        providerName
        agentName
        agentPhone
        fulfillmentStatus
        deliveryFees
      }
    }
  }
`;

const OrderTable = () => {
  const { id } = useParams();

  const [displayData, setDisplayData] = useState([]);
  const [orderData, setOrderyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [metaData, setMetaData] = useState({});
  const client = useApolloClient();

  useEffect(() => {
    const getData = async () => {
      client
        .query({
          query: GET_ORDER_DETAILS_BY_ID,
          variables: {
            orderId: id,
            billVisited: true,
          },
        })
        .then((result) => {
          setIsLoading(result.loading);
          setDisplayData(result.data.GetOrderDetailsByOrderId.products);
          setOrderyData(result.data.GetOrderDetailsByOrderId);
          setMetaData(result.data.GetOrderDetailsByOrderId.meta);
        })
        .catch((error) => {
          setisError(true);
        });
    };
    getData();
  }, []);

  const column = [
    { key: 1, heading: "Product Name (description)", value: "productName" },
    { key: 2, heading: "QTY", value: "orderQuantity" },
    { key: 3, heading: "MRP", value: "listingPrice" },
    { key: 4, heading: "Price", value: "orderPrice" },
    { key: 5, heading: "Total", value: "total" },
  ];
  const totalData = orderData.orderTotalAmount;
  const tableData1 = [
    {
      productName: "Total",
      MRP: "",
      orderPrice: "",
      orderQuantity: " ",
      discount: " ",
      total: totalData,
    },
  ];

  const displayDataWithCombinedNameAndDescription = displayData.map(item=>{
    return {
      ...item,
      productName: `${item.productName} (${item.productDes})`
    }
  })

  const handlePrint = () => {
    window.print();
  }

  const finalData = displayDataWithCombinedNameAndDescription.concat(tableData1);
  let date1 = metaData?.cA ? new Date(metaData.cA).toLocaleString() : null;
  return (
    <div className="overall-container">
      <meta charSet="UTF-8"></meta>
      <div className="print">
        <button className="print-button" onClick={handlePrint}> Print <IoMdPrint className="print-icon"/></button>
      </div>
      {isError ? (
        <h1>Error in loading data. Please try again later.</h1>
      ) : (
        <>
          {isLoading ? (
            <>
              <div className="center">Data is loading..</div>
              <div className="loader"></div>
              <div></div>
            </>
          ) : (
            <>
              <div className="App">
                <div></div>
              </div>
              <div className="App-normal">
                <img src={Logo} alt="dailybee logo" width="auto" height="50" />{" "}
              </div>
              <div className="App-bold">
                <b>{orderData.storeName} </b>
              </div>
              <div className="App-normal">
                <b>{orderData.storePhone} </b>
                <a className="callIcon" href={`tel:${orderData.storePhone || "NA"}`}><IoMdCall/></a>
              </div>
              <div className="App-right">
                <Row style={{ marginTop: 48 }}>
                  <Col span={8} offset={8}>
                    <div>OrderId # : {orderData.orderId || "NA"},</div>
                    <div>Order Date : {date1},</div>
                  </Col>
                </Row>
              </div>
              <div className="App-left">
                <Row>
                  <Col span={8}>
                    {/* <div> <b> Customer Details</b></div> */}
                    <div>Customer Name : {orderData.customerName || "NA"}</div>
                    <div>Customer Phone: {orderData.customerPhone || "NA"}
                    <a className="callIcon" href={`tel:${orderData.customerPhone || "NA"}`}><IoMdCall/></a></div>
                  </Col>
                </Row>
              </div>

              <div className="App-normal">
                <Table data={finalData} column={column} />
              </div>
              {orderData.logistics?.agentName && (
                <div className="App-left">
                <Row>
                  <Col span={8}>
                    <b>
                      <p>Delivery Agent Name : {orderData.logistics?.agentName || "NA"}</p>
                      <p style={{display: 'inline'}}>Delivery Agent Phone: {orderData.logistics?.agentPhone || "NA"}</p>
                      <a className="callIcon" href={`tel:${orderData.logistics?.agentPhone || "NA"}`}><IoMdCall /></a>
                    </b>
                    <b>
                      <div>
                        Thanks {orderData.customerName} for shopping with us!{" "}
                        <p>&#128591;</p>
                      </div>
                    </b>
                  </Col>
                </Row>
              </div>
              )}
              <div>
                <StarRating />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderTable;
